import { Row, Col, Form, Button, Table, Card, Modal, Input, Select, message } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: '10%'
    },
    {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        width: '25%'
    },
    {
        title: 'Rol',
        dataIndex: 'rol',
        key: 'rol',
        width: '10%'
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        width: '45%'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '5%'
    },
];

const ref_crear = React.createRef();
const ref_actualizar = React.createRef();

export const Usuarios = () => {

    const [usuarios, setUsuarios] = useState([]);
    const [usuario, setUsuario] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);
    const [modalActualizar, setModalActualizar] = useState(0);

    useEffect(() => {
        let isMounted = true;

        const llamada = async () => {
            const cargarUsuarios = [];
            const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/usuarios?$limit=-1`, { headers });
            if (data && data.length) {
                for (const item of data) {
                    item.key = item.id;
                    item.acciones = (
                        <Col className="text-right">
                            <Button onClick={() => {
                                setUsuario(item)
                                setModalActualizar(item.id)
                            }} className="mr-2 bg-warning border-0" type="primary" icon={<EditOutlined />} size={'small'} />
                            <Button onClick={() => borrarUsuario(item.id)} className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Col>
                    )
                    cargarUsuarios.push(item)
                }
                if (isMounted) setUsuarios(cargarUsuarios);
            }
        };
        llamada();
        return () => { isMounted = false }
    }, []);

    useEffect(() => {
        ref_actualizar.current?.setFieldsValue(usuario);
    }, [usuario]);

    const abrirModal = () => {
        setModalCrear(true)
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
    }

    const cerrarModalActualizar = () => {
        setModalActualizar(false);
        ref_actualizar.current?.resetFields();
    }

    const crearUsuario = async (datos) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/usuarios`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                message.success(`El usuario ${data.nombre} se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear el usuario`)
        }
    }

    const borrarUsuario = async (usuario) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/usuarios/${usuario}`, { headers })
            console.log(data)
            if (data) {
                message.success(`El usuario ${data.nombre} se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el usuario`)
        }
    }

    const actualizarUsuario = async (usuario) => {
        try {
            const { data } = await axios.patch(`${process.env.REACT_APP_RUTA_API}/usuarios/${modalActualizar}`, { ...usuario }, { headers })
            if (data) {
                ref_actualizar.current?.resetFields();
                setModalActualizar(false);
                message.success(`El usuario ${data.nombre} se ha actualizado con éxito`)
            }
        } catch (e) {
            message.error(`Error al actualizar el usuario`)
        }
    }

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Usuarios"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Usuario
                        </Button>}
                    >
                        <Table className="contabilidad" columns={columnas} size="small" pagination={false} dataSource={usuarios} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Usuario" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form name="crear" ref={ref_crear} onFinish={crearUsuario}>
                    <Form.Item name="email" label="Correo" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="rol" label="Rol" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Selecciona un rol para el usuario..."
                            allowClear
                        >
                            <Option value="admin">Admin</Option>
                            <Option value="cliente">Cliente</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal title="Actualizar Usuario" visible={modalActualizar > 0} onCancel={cerrarModalActualizar} footer={null}>
                <Form initialValues={usuario} name="actualizar" ref={ref_actualizar} onFinish={actualizarUsuario}>
                    <Form.Item name="email" label="Correo">
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item name="password" label="Contraseña" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="nombre" label="Nombre" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="rol" label="Rol" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            placeholder="Selecciona un rol para el usuario..."
                            allowClear
                        >
                            <Option value="admin">Admin</Option>
                            <Option value="cliente">Cliente</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Actualizar
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )

}

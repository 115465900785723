import React, { useEffect, useState, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Space } from 'antd';

import axios from 'axios';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import './imprimir.less';

import {
    Card,
    Col,
    Row,
    Divider,
    Spin
} from 'antd';


const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

let jwt;
let url_usuario;
let url_facturas;
if (localStorage.getItem("accessToken")) {
    try {

        jwt = jwt_decode(localStorage.getItem("accessToken"));
        url_usuario = `${process.env.REACT_APP_RUTA_API}/usuarios/${jwt.usuario.id}`;
        url_facturas = `${process.env.REACT_APP_RUTA_API}/facturas?$limit=-1`;
    } catch (error) { }

}


export const Imprimir = () => {

    const { lote } = useParams();
    const navigate = useNavigate();

    const [usuario, setUsuario] = useState({});
    const [logo, setLogo] = useState({});
    const [factura, setFactura] = useState([]);
    const [cargando, setCargando] = useState(false);

    const fecha = (dato) => {
        const date = new Date(dato)
        return date.toLocaleDateString('es-ES')
    }

    const [numeroInicial, setNumeroInicial] = useState(1);
    const [numeroFinal, setNumeroFinal] = useState('');

    const handleNumeroInicial = (e) => {
        setNumeroInicial(e.target.value);
    }

    const handleNumeroFinal = (e) => {
        setNumeroFinal(e.target.value);
    }

    useEffect(() => {
        const llamada = async () => {
            const respuesta = await axios.get(url_usuario, { headers });
            if (respuesta) {
                setUsuario(respuesta.data);
                if (respuesta.data.logo) {
                    setLogo(`${process.env.REACT_APP_RUTA_API}/${respuesta.data.logo}`);
                } else {
                    setLogo('');
                }
            }
            setCargando(true);
            const facturas = await axios.get(`${url_facturas}&lote_id=${lote}`, { headers });
            if (facturas && facturas.data.length) {
                setFactura(facturas.data)
            } else {
                navigate('/404')
            }
            setCargando(false);
        };
        llamada();
    }, [lote, navigate]);

    useEffect(() => {
        setNumeroFinal(factura.length);
    }, [factura]);

    return (

        <Spin tip="Descargando..." spinning={cargando}>
            {
                cargando && <div style={{ height: '100vh', backgroundColor: '#F0F2F5' }}></div>
            }
            {
                !cargando &&
                <div className="paginador ocultar">
                    <Form initialValues={{ numero_inicial: 1, numero_final: factura.length }}>
                        <Space direction="horizontal" align="baseline">
                            Total: {factura.length} factura/s.
                            Mostrar desde <Form.Item name="numero_inicial" label="" >
                                <Input type="number" min="1" max={factura.length} autoComplete="off" width={'100px'} onChange={handleNumeroInicial} />
                            </Form.Item>
                            hasta <Form.Item name="numero_final" label="" >
                                <Input type="number" min="1" max={factura.length} autoComplete="off" width={'100px'} onChange={handleNumeroFinal} />
                            </Form.Item>
                        </Space>
                    </Form>
                </div>
            }
            {
                !cargando && factura.map((item, i) =>
                    i >= numeroInicial - 1 && i <= numeroFinal - 1 &&
                    <Fragment key={item.id} >
                        <Col span={24} offset={6} className="py-3 ajustar">
                            <Card
                                id="tarjeta"
                                className="imprimir"
                                cover={
                                    <>
                                        <Row justify="space-between" align="middle" className="pt-3  text-muted texto text-encabezado">
                                            <Col span={6} className="pl-4">
                                                <div><b>{usuario.nombre}</b></div>
                                                <div>{usuario.nombre_calle}</div>
                                                <div>({usuario.codigo_postal}) {usuario.ciudad}</div>
                                                <div>{usuario.pais}</div>
                                                <div>{usuario.cif}</div>
                                            </Col>
                                            <Col span={6} className="text-right pr-4">
                                                <img
                                                    alt="logo"
                                                    src={logo}
                                                    width={125}
                                                />
                                            </Col>

                                        </Row>
                                        <Divider></Divider>
                                    </>
                                }
                            >
                                <Row justify="end">
                                    <Col span={12} ofsset={12} className="pt-5 text-muted texto text-cliente">
                                        <h5>{item.cliente_nombre}</h5>
                                        <h6>{item.pais.nombre}</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} ofsset={6} className="pl-3 pt-3 texto text-factura">
                                        <h1>Factura nº {item.numero}</h1>
                                        <h6><b>Fecha de factura</b></h6>
                                        <h5>{fecha(item.fecha_factura)}</h5>
                                    </Col>
                                </Row>
                                <div className="mx-3 mt-3 cuadrado">
                                    <Row justify="space-between" gutter={0}>
                                        <Col span={6} className="texto bottom text-tabla font-weight-bold">
                                            DESCRIPCIÓN
                                        </Col>
                                        <Col span={4} className="texto bottom left text-tabla font-weight-bold text-right">
                                            CANTIDAD
                                        </Col>
                                        <Col span={6} className="texto bottom left text-tabla font-weight-bold text-right">
                                            PRECIO UNITARIO
                                        </Col>
                                        <Col span={4} className="texto bottom left text-tabla font-weight-bold text-right">
                                            IMPUESTOS
                                        </Col>
                                        <Col span={4} className="texto bottom left text-tabla font-weight-bold text-right">
                                            IMPORTE
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" gutter={0}>
                                        <Col span={6} className="texto text-tabla">
                                            {item.producto_nombre_factura}
                                        </Col>
                                        <Col span={4} className="texto left text-tabla text-right">
                                            {1}
                                        </Col>
                                        <Col span={6} className="texto left text-tabla text-right">
                                            {1 * item.base_imponible.toFixed(2)} €
                                        </Col>
                                        <Col span={4} className="texto left text-tabla text-right">
                                            {1 * item.iva_tipo.toFixed(2)} %
                                        </Col>
                                        <Col span={4} className="texto left text-tabla bg-light text-right">
                                            {1 * item.base_imponible.toFixed(2)} €
                                        </Col>
                                    </Row>

                                </div>


                                <Row className="mx-3 mt-3" justify="space-between" gutter={0}>
                                    <Col span={12} offset={12}>
                                        <div className="cuadrado">
                                            <Row className="bottom" justify="space-between" gutter={0}>
                                                <Col span={12} className="texto  p-1 font-weight-bold">
                                                    Subtotal
                                                </Col>
                                                <Col span={12} className="texto p-1 text-right">
                                                    {1 * item.base_imponible.toFixed(2)} €
                                                </Col>
                                            </Row>
                                            <Row className="bottom" justify="space-between" gutter={0}>
                                                <Col span={12} className="texto p-1 font-weight-bold text-muted">
                                                    Impuestos
                                                </Col>
                                                <Col span={12} className="texto p-1 left text-right bg-light">
                                                    {1 * item.iva_valor.toFixed(2)} €
                                                </Col>
                                            </Row>
                                            <Row className="total" justify="space-between" gutter={0}>
                                                <Col span={12} className="texto p-1 font-weight-bold">
                                                    Total
                                                </Col>
                                                <Col span={12} className="texto p-1  font-weight-bold text-right">
                                                    {item.total.toFixed(2)} €
                                                </Col>
                                            </Row>
                                        </div>

                                    </Col>
                                </Row>
                            </Card>
                        </Col >
                        <div className="break"></div>
                    </Fragment >
                )
            }

        </Spin>

    )
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Form,
    Button,
    Card,
    Select,
    Spin,
    Col,
    Tabs,
    Table,
    message
} from 'antd';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import './estadisticas.less';
import { PrinterOutlined } from '@ant-design/icons';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const { Option } = Select;

const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

const options = {
    responsive: true,
    scales: {
        y: {
            min: 0
        }
    },
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
        },
    },
};

const columnas_salidas_base = [
    {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        width: '50%'
    },
    {
        title: 'Cantidad Kg',
        dataIndex: 'kg',
        key: 'kg',
        width: '30%'
    },
    {
        title: 'Cajas',
        dataIndex: 'cajas',
        key: 'cajas',
        width: '20%'
    }
];

const columnas_pagados_base = [
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        width: '10%'
    },
    {
        title: 'Payout',
        dataIndex: 'payout',
        key: 'payout',
        width: '15%'
    },
    {
        title: 'Totales',
        dataIndex: 'total',
        key: 'total',
        width: '15%'
    }
];

const columnas_resumen_falta = [
    {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        width: '5%'
    },
    {
        title: 'Kg',
        dataIndex: 'kg',
        key: 'kg',
        width: '10%'
    },
    {
        title: 'Importe Pendiente',
        dataIndex: 'importe',
        key: 'importe',
        width: '10%'
    }
];

const columnas_resumen_pagada = [
    {
        title: 'Formato',
        dataIndex: 'formato',
        key: 'formato',
        width: '5%'
    },
    {
        title: 'Nº Cajas',
        dataIndex: 'cajas',
        key: 'cajas',
        width: '10%'
    },
    {
        title: 'Kg',
        dataIndex: 'kg',
        key: 'kg',
        width: '10%'
    },
    {
        title: 'Importe',
        dataIndex: 'importe',
        key: 'importe',
        width: '10%'
    }
];

const columnas_resumen_salida = [
    {
        title: 'Formato',
        dataIndex: 'formato',
        key: 'formato',
        width: '5%'
    },
    {
        title: 'Nº Cajas',
        dataIndex: 'cajas',
        key: 'cajas',
        width: '10%'
    },
    {
        title: 'Kg',
        dataIndex: 'kg',
        key: 'kg',
        width: '10%'
    }
];


export const Estadisticas = () => {

    const [temporada, setTemporada] = useState([]);
    const [cargando, setCargando] = useState(false);
    const [estadisticas, setEstadisticas] = useState(null);
    const [productos_errores, setProductosErrores] = useState([]);
    const [salidas, setSalidas] = useState([]);
    const [pagados, setPagados] = useState([]);
    const [resumen_falta, setResumenFalta] = useState([]);
    const [resumen_pagada, setResumenPagada] = useState([]);
    const [resumen_salida, setResumenSalida] = useState([]);
    const [resumen_pago_medio, setPagoMedio] = useState(0);
    const [columnas_salidas, setColumnasSalidas] = useState([]);
    const [columnas_pagados, setColumnasPagados] = useState([]);
    const [datos_chart, setDatosChart] = useState({});
    const [imprimir, setImprimir] = useState(false);

    useEffect(() => {
        const llamada = async () => {
            const cargarLote = [];
            const temporadas = await axios.get(`${process.env.REACT_APP_RUTA_API}/temporadas?$limit=-1`, { headers });
            if (temporadas.data && temporadas.data.length) {
                for (const item of temporadas.data) {
                    cargarLote.push(item)
                }
                setTemporada(cargarLote)
            }
        };
        llamada();
    }, []);

    useEffect(() => {
        const faltan = {
            keys: [],
            salidas: {},
            pagadas: {},
            totales: {}
        };

        const salidas = [];
        if (estadisticas && estadisticas.error_productos) {
            setProductosErrores(estadisticas.error_productos);
        }
        if (estadisticas && estadisticas.salidas) {

            const productos = {};
            for (const key in estadisticas.salidas) {
                if (Object.hasOwnProperty.call(estadisticas.salidas, key)) {
                    const element = estadisticas.salidas[key];
                    if (!productos[element.nombre]) {
                        productos[element.nombre] = 0
                    }
                    if (!faltan.salidas[element.nombre]) {
                        faltan.salidas[element.nombre] = { total_kg: element.total_kg, precio_medio: 0 };
                        faltan.keys.push(element.nombre);
                    } else {
                        faltan.salidas[element.nombre].total_kg += element.total_kg;
                    }
                }
            }

            const lcolumnas_salidas = [...columnas_salidas_base];
            setColumnasSalidas(lcolumnas_salidas);

            const total = {
                key: 0,
                producto: (<b>Totales</b>),
                kg: 0,
                cajas: 0
            };

            const destrio = {
                key: 0,
                producto: (<b>Totales Destrio</b>),
                kg: 0,
                cajas: '-'
            }
            for (const key in estadisticas.salidas) {
                if (Object.hasOwnProperty.call(estadisticas.salidas, key)) {
                    const element = estadisticas.salidas[key];
                    salidas.push({
                        key,
                        producto: <>{element.nombre}
                            {element.destrio && <><br /><small>-Destrío: {element.destrio}Kg</small></>}</>,
                        kg: element.total_kg,
                        cajas: element.cantidad
                    });
                    total.kg += element.total_kg;
                    total.cajas += element.cantidad;
                    if (element.destrio) {
                        destrio.kg += element.destrio;
                    }
                }
            }
            salidas.push(total);
            salidas.push(destrio);
        }
        setSalidas(salidas);

        const pagados = [];
        let total_productos = 0;
        if (estadisticas && estadisticas.pagadas) {
            const productos = {};
            for (const key in estadisticas.pagadas) {
                if (Object.hasOwnProperty.call(estadisticas.pagadas, key)) {
                    const element = estadisticas.pagadas[key];
                    for (const key2 in element.productos) {
                        if (key2 !== '0' && Object.hasOwnProperty.call(element.productos, key2)) {
                            productos[key2] = 0;
                            total_productos++;
                        }
                    }
                }
            }

            const lcolumnas_pagados = [...columnas_pagados_base];
            lcolumnas_pagados.splice(2, 0, ...Object.keys(productos).map(item => {
                return {
                    title: item,
                    dataIndex: item,
                    key: item,
                    width: ((100 - 40) / total_productos) + '%'
                }
            }
            ));
            setColumnasPagados(lcolumnas_pagados);

            const total_euros = {
                key: 0,
                ...productos,
                fecha: (<b>Totales (€)</b>),
                total: 0
            }

            const total_kg = {
                key: 0,
                ...productos,
                fecha: (<b>Totales (Kg)</b>),
                total: 0
            }

            const total_medio = {
                key: 0,
                ...productos,
                fecha: (<b>Totales (€/Kg)</b>),
                total: 0
            }

            for (const key in estadisticas.pagadas) {
                if (Object.hasOwnProperty.call(estadisticas.pagadas, key)) {
                    const element = estadisticas.pagadas[key];
                    pagados.push({
                        key: key + 'a',
                        fecha: element.fecha.substr(0, 10),
                        payout: element.nombre,
                        total: parseFloat(element.importe).toFixed(2) + '€',
                    });

                    const datos = {
                        k: {
                            key: key + 'k',
                            payout: 'Kg',
                        },
                        p: {
                            key: key + 'p',
                            payout: 'Pagado',
                        },
                        m: {
                            key: key + 'm',
                            payout: 'Precio medio €/Kg',
                        }
                    }

                    for (const key2 in element.productos) {
                        if (key2 !== '0' && Object.hasOwnProperty.call(element.productos, key2)) {
                            const element2 = element.productos[key2];
                            total_euros[key2] += element2.importe;
                            total_euros[key2] = parseFloat(total_euros[key2]).toFixed(2);
                            total_kg[key2] += element2.peso;
                            total_kg[key2] = parseFloat(total_kg[key2]).toFixed(2);
                            total_medio[key2] = total_euros[key2] / total_kg[key2];
                            total_medio[key2] = parseFloat(total_medio[key2]).toFixed(2);
                            if (!faltan.pagadas[key2]) {
                                faltan.pagadas[key2] = {
                                    total_kg: element2.peso,
                                    importe: element2.importe
                                };
                                if (!faltan.keys.includes(key2)) {
                                    faltan.keys.push(key2);
                                }
                            } else {
                                faltan.pagadas[key2].total_kg += element2.peso;
                                faltan.pagadas[key2].importe += element2.importe;
                            }
                        }
                        datos.k[key2] = parseFloat(element.productos[key2].peso).toFixed(2);
                        datos.p[key2] = parseFloat(element.productos[key2].importe).toFixed(2);
                        datos.m[key2] = parseFloat(element.productos[key2].importe / element.productos[key2].peso).toFixed(2);
                    }
                    pagados.push(datos.k);
                    pagados.push(datos.p);
                    pagados.push(datos.m);
                    total_euros.total += element.importe;
                    total_kg.total += element.peso;
                    total_medio.total = total_euros.total / total_kg.total;
                }
            }
            total_euros.total = parseFloat(total_euros.total).toFixed(2) + '€';
            total_kg.total = parseFloat(total_kg.total).toFixed(2) + 'Kg';
            total_medio.total = parseFloat(total_medio.total).toFixed(2) + '€/Kg';
            pagados.push(total_euros);
            pagados.push(total_kg);
            pagados.push(total_medio);
        }
        setPagados(pagados);

        const lresumen_falta = [];
        if (faltan.keys.length) {

            const total = {
                key: 0,
                producto: (<b>Totales</b>),
                kg: 0,
                importe: 0
            }

            for (const key of faltan.keys) {
                const cantidad_salida = faltan.salidas[key] ? faltan.salidas[key].total_kg : 0;
                const cantidad_pagada = faltan.pagadas[key] ? faltan.pagadas[key].total_kg : 0;
                const cantidad_falta = cantidad_salida - cantidad_pagada;
                const precio_medio = faltan.pagadas[key] ? faltan.pagadas[key].importe / faltan.pagadas[key].total_kg : 0;
                const linea = {
                    key,
                    producto: key,
                    kg: parseFloat(cantidad_falta).toFixed(2),
                    importe: (precio_medio) ? parseFloat(cantidad_falta * precio_medio).toFixed(2) : '**No hay precio medio**'
                }
                total.kg += cantidad_falta;
                total.importe += parseFloat(cantidad_falta * precio_medio);
                lresumen_falta.push(linea);
            }
            total.kg = parseFloat(total.kg).toFixed(2);
            total.importe = parseFloat(total.importe).toFixed(2);
            lresumen_falta.push(total);
        }
        setResumenFalta(lresumen_falta);

    }, [estadisticas]);

    const ordenar = (a, b) => {
        if (a.id < b.id) { return 1; }
        if (a.id > b.id) { return -1; }
        return 0;
    }

    const onFinish = async (campos) => {
        setCargando(true)
        const respuesta = await axios.post(`${process.env.REACT_APP_RUTA_API}/estadisticas/`, { ...campos }, { headers });
        if (respuesta && respuesta.data) {
            setEstadisticas(respuesta.data);
        } else {
            message.error('Error al obtener estadísticas')
        }
        setCargando(false)
    };

    return (

        <Spin tip="Descargando..." spinning={cargando}>
            {
                (!imprimir) && <Col span={22} offset={1} className="pt-5">
                    <Card title="Estadísticas" >
                        <Form
                            name="ver_estadisticas"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 14 }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                label="Selecciona una campaña"
                                name="temporada"
                                rules={[{ required: true, message: '¡Selecciona una campaña!' }]}
                                wrapperCol={{ span: 12 }}
                            >
                                <Select placeholder="Selecciona una campaña" style={{ width: '200px' }}>
                                    {
                                        temporada.sort(ordenar).map(item => <Option value={item.id} key={item.id}>{item.nombre}</Option>)
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                                <Button type="primary" htmlType="submit">
                                    Ver estadísticas
                                </Button>
                                {
                                    (estadisticas && estadisticas.salidas) && <Button type="primary" icon={<PrinterOutlined />} className="ml" danger onClick={() => {
                                        setImprimir(true);
                                        setTimeout(() => {
                                            window.print();
                                        }, 1000);
                                    }}>
                                        Imprimir
                                    </Button>
                                }
                            </Form.Item>
                        </Form>
                        {
                            (estadisticas && productos_errores && !!productos_errores.length) && <div className="alert alert-danger">
                                <h6>Las siguientes productos no están creados</h6>
                                <ul>
                                    {
                                        productos_errores.map(item => <li key={item}>{item}</li>)
                                    }
                                </ul>
                            </div>
                        }
                        {
                            (estadisticas) && <Tabs>
                                <Tabs.TabPane tab="Salidas" key="item-1">
                                    <Table className="salidas" columns={columnas_salidas} size="small" pagination={false} dataSource={salidas} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Pagados" key="item-2">
                                    <Table className="pagados" columns={columnas_pagados} size="small" pagination={false} dataSource={pagados} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Resumen" key="item-3">
                                    <h5>Falta por pagar</h5>
                                    <Table className="resumen_falta" columns={columnas_resumen_falta} size="small" pagination={false} dataSource={resumen_falta} />
                                </Tabs.TabPane>
                            </Tabs>
                        }
                    </Card>
                </Col>
            }
            {
                (imprimir) && <Col span={24} offset={6} className="py-3 ajustar">
                    <Card
                        id="tarjeta"
                        className="imprimir"
                        cover={
                            <>

                            </>
                        }
                    >
                        <Button className="ocultar" type="primary" onClick={() => setImprimir(false)}>Volver</Button>
                        <h4>Resumen</h4>
                        <h5>Salidas</h5>
                        <Table className="salidas" columns={columnas_salidas} size="small" pagination={false} dataSource={salidas} />
                        <hr />
                        <h5>Pagados</h5>
                        <Table className="pagados" columns={columnas_pagados} size="small" pagination={false} dataSource={pagados} />
                        <hr />
                        <h5>Resumen faltan por pagar</h5>
                        <Table className="resumen_falta" columns={columnas_resumen_falta} size="small" pagination={false} dataSource={resumen_falta} />
                    </Card>
                </Col>
            }
        </Spin>

    )
}

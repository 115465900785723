import React, { useEffect, useState, Fragment } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Space } from 'antd';

import axios from 'axios';
import { jwtDecode as jwt_decode } from 'jwt-decode';
import './hojacmr.less';

import {
    Card,
    Col,
    Row,
    Divider,
    Spin
} from 'antd';


const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

let jwt;
let url_usuario;
let url_facturas;
if (localStorage.getItem("accessToken")) {
    try {

        jwt = jwt_decode(localStorage.getItem("accessToken"));
        url_usuario = `${process.env.REACT_APP_RUTA_API}/usuarios/${jwt.usuario.id}`;
        url_facturas = `${process.env.REACT_APP_RUTA_API}/facturas?$limit=-1`;
    } catch (error) { }

}


export const Hojacmr = () => {

    const [usuario, setUsuario] = useState({});
    const [cargando, setCargando] = useState(false);

    const fecha = (dato) => {
        const date = new Date(dato)
        return date.toLocaleDateString('es-ES')
    }


    return (

        <Spin tip="Descargando..." spinning={cargando}>
            {
                cargando && <div style={{ height: '100vh', backgroundColor: '#F0F2F5' }}></div>
            }
            <Fragment >
                <Col span={24} offset={6} className="py-3 ajustar">
                    <Card
                        id="tarjeta"
                        className="imprimir"
                        cover={
                            <>

                            </>
                        }
                    >
                        <Row>
                            <Col span={18}>
                                <Row>
                                    <h3>1. Sender</h3>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <div className="text-uppercase">Fecha:</div>
                                <div className="text-uppercase">Nº:</div>
                            </Col>
                        </Row>
                    </Card>
                </Col >
                <div className="break"></div>
            </Fragment >

        </Spin >

    )
}

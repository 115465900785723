import { Row, Col, Button, Table, Card, message, Popconfirm } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: '10%'
    },
    {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        width: '25%'
    },
    {
        title: 'Fecha factura',
        dataIndex: 'fecha_factura',
        key: 'fecha_factura',
        width: '25%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Factura desde',
        dataIndex: 'factura_numero_desde',
        key: 'factura_numero_desde',
        width: '10%'
    },
    {
        title: 'Factura hasta',
        dataIndex: 'factura_numero_hasta',
        key: 'factura_numero_hasta',
        width: '10%'
    },
    {
        title: 'Fecha creación',
        dataIndex: 'fecha_creacion',
        key: 'fecha_creacion',
        width: '15%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '5%'
    },
];

const ref_actualizar = React.createRef();

export const Geslotes = () => {

    const [actualizar, setActualizar] = useState([]);
    const [lotes, setLotes] = useState([]);

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/lotes?$sort[id]=-1&$limit=-1`, { headers });
        setActualizar(data);
    }

    useEffect(() => {
        actualizador()
    }, []);

    useEffect(() => {
        let isMounted = true;

        const cargarLotes = [];
        if (actualizar && actualizar.length) {
            for (const item of actualizar) {
                item.key = item.id;
                item.acciones = (
                    <Col className="text-right">
                        <Popconfirm placement="topLeft" title={'Esta operación no puede deshacerse, ¿quieres continuar?'} onConfirm={() => borrarLote(item.id)} okText="Aceptar" cancelText="Cancelar" icon="" className="bg-danger border-0">
                            <Button className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Popconfirm>

                    </Col>
                )
                cargarLotes.push(item)
            }
            if (isMounted) setLotes(cargarLotes);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    const borrarLote = async (lote) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/lotes/${lote}`, { headers })
            actualizador();
            if (data) {
                message.success(`El lote se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar el lote`)
        }
    }

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Lotes"
                    >
                        <Table className="contabilidad" columns={columnas} size="small" pagination={false} dataSource={lotes} />
                    </Card>
                </Col>
            </Row>
        </>

    )

}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import esES from 'antd/es/date-picker/locale/es_ES';

import {
    Form,
    InputNumber,
    Button,
    Card,
    message,
    DatePicker,
    Select,
    Spin,
    Col
} from 'antd';

const { Option } = Select;

const headers = {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`
}

export const Numeros = () => {

    const [lote, setLote] = useState([]);
    const [cargando, setCargando] = useState(false);

    useEffect(() => {
        const llamada = async () => {
            const cargarLote = [];
            const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/lotes?contador_id=$null&$limit=-1`, { headers });
            if (data && data.length) {
                for (const item of data) {
                    cargarLote.push(item)
                }
                setLote(cargarLote)
            }
        };
        llamada();
    }, []);

    const onFinish = async ({ lote, numero, fecha }) => {
        setCargando(true)
        const respuesta = await axios.patch(`${process.env.REACT_APP_RUTA_API}/lotes/${lote}`, { numero, fecha: fecha ? fecha : null }, { headers }).catch(controlErrores);
        if (respuesta && respuesta.data) {
            message.success(` El lote "${respuesta.data.nombre}" se ha modificado con éxito.`);
        }
        setCargando(false)
    };

    const controlErrores = (e) => {
        // message.error(`${e.response.data.message}. Cambia el nombre del lote`);
    }

    const ordenar = (a, b) => {
        if (a.id < b.id) {
            return 1;
        }
        if (a.id > b.id) {
            return -1;
        }
        return 0;
    }

    return (
        <Spin tip="Subiendo..." spinning={cargando}>
            <Col span={16} offset={4} className="py-5">
                <Card title="Cambiar Datos de las Facturas" >
                    <Form
                        name="cambiar_facturas"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 14 }}
                        onFinish={onFinish}
                        initialValues={{ 'numero': 1 }}
                    >
                        <Form.Item
                            label="Selecciona un lote"
                            name="lote"
                            rules={[{ required: true, message: '¡Selecciona un lote!' }]}
                            wrapperCol={{ span: 12 }}
                        >
                            <Select placeholder="Selecciona un lote" style={{ width: '200px' }}>
                                {
                                    lote.sort(ordenar).map(item => <Option value={item.id} key={item.id}>{item.nombre}</Option>)
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item name="fecha" label="Fecha Facturas" rules={[{ type: 'object' }]}>
                            <DatePicker style={{ width: '200px' }} placeholder="Selecciona fecha..." locale={esES} />
                        </Form.Item>

                        <Form.Item label="Número Primera Factura" required>
                            <Form.Item
                                name="numero"
                                noStyle
                                rules={[{ required: true, message: '¡Campo Obligatorio!' }]}
                            >
                                <InputNumber min={1} style={{ width: '200px' }} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                            <Button type="primary" htmlType="submit">
                                Cambiar Datos
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Spin>
    )
}

import { Row, Col, Button, Table, Card, message, Popconfirm, Form, InputNumber, DatePicker, Modal, Select, Tooltip } from 'antd'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const token = localStorage.getItem("accessToken")

const headers = {
    Authorization: `Bearer ${token}`
}

const columnas = [
    {
        title: 'Fecha movimiento',
        dataIndex: 'fecha_movimiento',
        key: 'fecha_movimiento',
        width: '30%',
        render: text => new Date(text).toLocaleDateString('es-ES')
    },
    {
        title: 'Producto',
        dataIndex: 'producto',
        key: 'producto',
        width: '20%',
        render: text => text?.nombre_propio
    },
    {
        title: 'Formato de caja (Kg)',
        dataIndex: 'peso',
        key: 'peso',
        width: '20%',
        align: 'right'
    },
    {
        title: 'Cantidad de cajas',
        dataIndex: 'cantidad',
        key: 'cantidad',
        width: '20%',
        align: 'right'
    },
    {
        title: 'Acciones',
        dataIndex: 'acciones',
        key: 'acciones',
        width: '10%'
    },
];

const ref_crear = React.createRef();

export const Gessalidas = () => {

    const [actualizar, setActualizar] = useState([]);
    const [salidas, setSalidas] = useState([]);
    const [productos, setProductos] = useState([]);
    const [modalCrear, setModalCrear] = useState(false);

    const actualizador = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/salidas?$sort[fecha_movimiento]=-1&$limit=-1`, { headers });
        setActualizar(data);
    }

    const actualizadorProductos = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_RUTA_API}/productos?$sort[nombre_propio]=-1&$limit=-1`, { headers });
        setProductos(data?.map(item => { return { label: `${item.nombre_propio}`, value: item.id } }));
    }

    useEffect(() => {
        actualizador();
        actualizadorProductos();
    }, []);

    useEffect(() => {
        let isMounted = true;

        const cargarSalidas = [];
        if (actualizar && actualizar.length) {
            for (const item of actualizar) {
                item.key = item.id;
                item.acciones = (
                    <Col className="text-right">
                        <Tooltip title="Imprimir hoja mercancía">
                            <Link to={`/hojamercancia/${item.fecha_movimiento.split('T')[0]}`}>
                                <Button className="bg-info border-0" type="primary" icon={<PrinterOutlined />} size={'small'} />
                            </Link>
                        </Tooltip>
                        <Popconfirm placement="topLeft" title={'Esta operación no puede deshacerse, ¿quieres continuar?'} onConfirm={() => borrarSalida(item.id)} okText="Aceptar" cancelText="Cancelar" icon="" className="bg-danger border-0">
                            <Button className="bg-danger border-0" type="primary" icon={<DeleteOutlined />} size={'small'} />
                        </Popconfirm>

                    </Col>
                )
                cargarSalidas.push(item)
            }
            if (isMounted) setSalidas(cargarSalidas);
        }
        return () => { isMounted = false }
    }, [actualizar]);

    const abrirModal = () => {
        setModalCrear(true)
    }

    const cerrarModalCrear = () => {
        setModalCrear(false);
        ref_crear.current?.resetFields();
        actualizador();
    }

    const borrarSalida = async (salida) => {
        try {
            const { data } = await axios.delete(`${process.env.REACT_APP_RUTA_API}/salidas/${salida}`, { headers })
            actualizador();
            if (data) {
                message.success(`La salida se ha borrado con éxito`)
            }
        } catch (e) {
            message.error(`Error al borrar la salida`)
        }
    }

    const crearSalida = async (datos) => {
        try {
            const { data } = await axios.post(`${process.env.REACT_APP_RUTA_API}/salidas`, { ...datos }, { headers })
            if (data) {
                ref_crear.current?.resetFields();
                setModalCrear(false);
                actualizador();
                message.success(`La salida se ha creado con éxito`)
            }
        } catch (e) {
            message.error(`Error al crear la salida`)
        }
    }

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <>
            <Row className="px-5 pt-5" >
                <Col span={24}>
                    <Card
                        title="Gestionar Salidas"
                        extra={<Button type="primary" htmlType="submit" onClick={abrirModal}>
                            Crear Salida
                        </Button>}
                    >
                        <Table className="salidas" columns={columnas} size="small" pagination={false} dataSource={salidas} />
                    </Card>
                </Col>
            </Row>
            <Modal title="Crear Salida" visible={modalCrear} onCancel={cerrarModalCrear} footer={null}>
                <Form initialValues={{ numero_actual: 1 }} name="crear" ref={ref_crear} onFinish={crearSalida}>
                    <Form.Item name="fecha_movimiento" label="Fecha movimiento" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <DatePicker />
                    </Form.Item>
                    <Form.Item name="producto_id" label="Producto" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <Select
                            showSearch
                            placeholder="Selecciona un producto"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={productos}
                        />
                    </Form.Item>
                    <Form.Item name="peso" label="Formato de caja (Kg)" rules={[{ required: true, message: 'Campo Requerido' }]}>
                        <InputNumber parser={value => value.replace(new RegExp(/,/g), '.')} />
                    </Form.Item>
                    <Row>
                        <Col span={12}>
                            <Form.Item name="cantidad" label="Cantidad de cajas" rules={[{ required: true, message: 'Campo Requerido' }]}>
                                <InputNumber
                                    parser={value => value.replace(new RegExp(/,/g), '.')}
                                    onChange={(value) => {
                                        ref_crear.current?.setFieldsValue({ total_kg: value * ref_crear.current?.getFieldValue('peso') })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="total_kg" label="Total Kg" rules={[{ required: true, message: 'Campo Requerido' }]}>
                                <InputNumber
                                    parser={value => value.replace(new RegExp(/,/g), '.')}
                                    onChange={(value) => {
                                        ref_crear.current?.setFieldsValue({ cantidad: value / ref_crear.current?.getFieldValue('peso') })
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ offset: 20, span: 24 }} >
                        <Button type="primary" htmlType="submit">
                            Crear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    )

}
